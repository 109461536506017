import '@/lib/configure/whyDidYouRender';
import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';
import { AnimatePresence } from 'framer-motion';
import Head from 'next/head';

import { Provider as ModalProvider } from '@/components/modals';
import config from '@/config';
import { useApollo } from '@/graphql/apollo';
import configureSentry from '@/lib/configure/sentry';
import { useFirebase } from '@/lib/providers/firebase';
import FullStory from '@/services/fullstory';
import theme from '@/theme';

configureSentry();

if (config.firebaseConfig && !firebase.apps.length) {
  firebase.initializeApp(config.firebaseConfig);
}

export default function App({ Component, pageProps }): JSX.Element {
  const { getToken } = useFirebase();
  const apolloClient = useApollo(pageProps.initialApolloState, getToken);
  apolloClient.resetStore();

  return (
    <Sentry.ErrorBoundary>
      <FullStory />
      <ApolloProvider client={apolloClient}>
        <ChakraProvider resetCSS theme={theme}>
          <ModalProvider modals={<>{/* Put global modals here */}</>}>
            <>
              <Head>
                <title>{config.title}</title>
                <meta
                  content='width=device-width, initial-scale=1'
                  name='viewport'
                />
                <meta content='ie=edge' httpEquiv='x-ua-compatible' />
              </Head>
              <AnimatePresence exitBeforeEnter>
                <Component {...pageProps} />
              </AnimatePresence>
            </>
          </ModalProvider>
        </ChakraProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}
