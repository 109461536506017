import { Debug } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from '@/config';

const configure = () => {
  Sentry.init({
    attachStacktrace: true,
    dsn: config.sentry.dsn,
    environment: config.env,
    tracesSampleRate: 1.0,
    integrations: [
      new Integrations.BrowserTracing(),
      ...(config.env === 'production'
        ? []
        : [
            new Debug({
              // Trigger DevTools debugger instead of using console.log
              debugger: false,
            }),
          ]),
    ],
  });
};

export default configure;
