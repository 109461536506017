const typography = {
  fontWeights: {
    light: 300,
    normal: 500,
    medium: 600,
    bold: 700,
  },

  fonts: {
    body: "'Public Sans', system-ui, sans-serif",
    heading: 'co-headline, sans-serif;',
  },

  fontSizes: {
    xxs: 8.192,
    xs: 10.24,
    sm: 12.8,
    base: 16,
    md: 20,
    lg: 25,
    xl: 31.25,
    xxl: 39.0625,
    xxxl: 60,
  },
};

export type Typography = typeof typography;

export default typography;
