import breakpoints from './breakpoints';
import colors from './colors';
import sizes from './sizes';
import space from './space';
import typography from './typography';

const theme = {
  breakpoints,
  space,
  ...typography,
  header: {
    height: 80,
  },
  sizes,
  colors,
};

export type Theme = typeof theme;

export default theme;
