import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  sm: '576px',
  phablet: '576px',
  md: '768px',
  tablet: '768px',
  lg: '992px',
  desktop: '992px',
  xl: '1200px',
  largeDesktop: '1200px',
});

export default breakpoints;
