const scale = {
  xs: 4,
  sm: 8,
  md: 16,
  base: 24,
  lg: 32,
  xl: 64,
  xxl: 128,
};
export default {
  ...scale,
};
