export default {
  darkGrey: '#a4a4a4',
  border: '#E8EBEF',
  lightBorder: '#E2E4E9',
  bg: '#FAFAFC',
  blackText: '#303446',
  amberBg: '#FFF5D6',
  amberText: '#FFBF00',
  yellowText: '#DFCD00',
  yellowBg: '#FFFBD7',
  lightYellowBg: '#FEF5E6',
  orangeText: '#FDD4BE',
  blueBg: '#F1F5F9',
  blueText: '#27303F',
  greenText: '#289375',
  greenBg: '#DEF7EC',
  grayText: '#A7AEC0',
  brand: {
    solonYellow: '#F9B036',
    solonBlue: '#414460',
    solonGrey: '#b3bcc4',
    solonPurple: '#6e5480',
    solonTeal: '#3b8c9a',
    solonDarkGrey: '#575756',
    omcRed: '#b4363a',
    omcOrange: '#ee7633',
  } as any,
  solonYellow: {
    50: '#FDE5BC',
    100: '#FCD89B',
    200: '#FBCA79',
    300: '#FABD58',
    400: '#F9B036',
    500: '#CF932D',
    600: '#A67524',
    700: '#7D581B',
    800: '#533B12',
    900: '#2A1D09',
  },
  solonBlue: {
    50: '#c0c1ca',
    100: '#a0a2b0',
    200: '#808295',
    300: '#61637b',
    400: '#414460',
    500: '#363950',
    600: '#2b2d40',
    700: '#212230',
    800: '#161720',
    900: '#0b0b10',
  },
  solonGrey: {
    50: '#e6e9eb',
    100: '#d9dee2',
    200: '#ccd2d8',
    300: '#c0c7ce',
    400: '#b3bcc4',
    500: '#959da3',
    600: '#777d83',
    700: '#5a5e62',
    800: '#3c3f41',
    900: '#1e1f21',
  },
  solonPurple: {
    50: '#cfc6d5',
    100: '#b7aac0',
    200: '#9e8daa',
    300: '#867195',
    400: '#6e5480',
    500: '#5c466b',
    600: '#493855',
    700: '#372a40',
    800: '#251c2b',
    900: '#120e15',
  },
  solonTeal: {
    50: '#bed9dd',
    100: '#9dc6cd',
    200: '#7cb2bc',
    300: '#5c9fab',
    400: '#3b8c9a',
    500: '#317580',
    600: '#275d67',
    700: '#1e464d',
    800: '#142f33',
    900: '#0a171a',
  },
  solonDarkGrey: {
    50: '#c7c7c7',
    100: '#ababab',
    200: '#8f8f8e',
    300: '#737372',
    400: '#575756',
    500: '#494948',
    600: '#3a3a39',
    700: '#2c2c2b',
    800: '#1d1d1d',
    900: '#0f0f0e',
  },
  omcRed: {
    50: '#e6bcbd',
    100: '#da9b9d',
    200: '#cd797c',
    300: '#c1585b',
    400: '#b4363a',
    500: '#962d30',
    600: '#782427',
    700: '#5a1b1d',
    800: '#3c1213',
    900: '#1e090a',
  },
  omcOrange: {
    50: '#f9d1bb',
    100: '#f7bb99',
    200: '#f4a477',
    300: '#f18d55',
    400: '#ee7633',
    500: '#c6622b',
    600: '#9f4f22',
    700: '#773b1a',
    800: '#4f2711',
    900: '#281409',
  },
};
