/* eslint-disable no-console */
import { useMemo } from 'react';

import { from, ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import log from '@/services/log';

let apolloClient;
function createApolloClient(getToken: Function) {
  const httpLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_CORE_API,
  });
  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken();
    return {
      headers: {
        ...headers,
        authorization: token || '',
      },
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(async ({ message }) => {
        log.error(`[GraphQL error]: Message: ${message}`);
      });
    if (networkError) log.error(`[Network error]: ${networkError}`);
  });
  const links = from([authLink, errorLink, httpLink]);
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: links,
    cache: new InMemoryCache(),
  });
}
export function initializeApollo(initialState = null, getToken) {
  const ac = apolloClient ?? createApolloClient(getToken);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = ac.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    ac.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return ac;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = ac;
  return ac;
}
export function useApollo(initialState, getToken) {
  const store = useMemo(() => initializeApollo(initialState, getToken), [
    initialState,
  ]);
  return store;
}
